<template>
  <div v-if="api.loading.value || loading" class="p-4">
    <small>Aan het zoeken...</small>
  </div>
  <div v-else class="p-4">
    <UITabs class-button="block mb-4" class="flex w-full" :tabs="tabs" v-model="tab" v-show="!isBedrijvenOnly" />

    <div class="grid lg:grid-cols-3 xl:grid-cols-4 gap-4">
      <div v-for="item in list.items || []" :key="`${tab}${item.id}${item.klanten_info_id || '?'}`" class="card justify-between items-start flex flex-col">
        <component :is="list.component" label="" :klant="item" :facturatie="item" class="flex-col items-start" />
        <div class="flex w-full justify-between mt-4">
          <router-link v-if="isPageSelection" :to="{ query: { ...route.query, [list.query]: item[list.queryProp], t: Date.now() } }" @click="handleSelection(item)" class="btn success">+ Invullen</router-link>
          <router-link v-else :to="`/ritten/nieuw?${list.query}=${item.id}&t=${Date.now()}`" class="btn success">+ Nieuwe rit</router-link>
          <router-link
            v-if="typeof list.getProfileRoute === 'function' && !store.getters.isKantoorPermission"
            :disabled="!item.id"
            class="btn info"
            :to="list.getProfileRoute(item.id || 'undefined')"
          >
            Profiel bekijken
          </router-link>
        </div>
      </div>
      <small v-if="!list.items?.length" class="lg:col-span-3 xl:col-span-4">Geen {{ list.empty }} gevonden.</small>
    </div>
  </div>
</template>

<script setup>
import { computed, ref, watch, onMounted } from 'vue'
import { useDebounceFn } from '@vueuse/core'

import UITabs from '@/components/UI/Tabs.vue'
import KlantLijn from '../Planning/KlantLijn.vue'
import FacturatieLijn from '../Planning/FacturatieLijn.vue'

import useApi from '@/hooks/useApi'
import useSearchItem from '@/hooks/useSearchItem'
import { useRoute } from 'vue-router'
import store from '@/store'

const props = defineProps({
  search: String,
})

const route = useRoute()

const klanten = ref([])
const facturen = ref([])
const loading = ref(false)

const isPageSelection = computed(() => route.name?.indexOf('Facturatie') === 0 || ['OfferteNieuw', 'OfferteBewerken', 'RittenNieuw', 'RittenBewerken'].includes(route.name))
const isBedrijvenOnly = computed(() => route.name?.indexOf('Facturatie') === 0)

const tabs = computed(() => [`Klanten (${klanten.value?.length})`, `Bedrijven (${facturen.value?.length})`])
const tab = ref(0)

const list = computed(() => {
  if (tab.value === 0)
    return {
      items: klanten.value,
      component: KlantLijn,
      query: 'klant_info',
      queryProp: 'klanten_info_id',
      empty: 'klantgegevens',
      getProfileRoute: (klant_id) => {
        return { name: 'KlantInfo', params: { klant_id } }
      },
    }

  if (tab.value === 1)
    return {
      items: facturen.value,
      component: FacturatieLijn,
      query: 'facturatie',
      queryProp: 'id',
      empty: 'bedrijfsgegevens',
      getProfileRoute: (facturatie_id) => {
        return { name: 'BedrijvenInfoBoekingen', params: { facturatie_id } }
      },
    }

  return {}
})

const api = useApi()
const apiData = computed(() => ({ q: props.search }))

const handleSearch = useDebounceFn(() => {
  api.get('GLOBAL_SEARCH', apiData).then(({ data }) => {
    klanten.value = isBedrijvenOnly.value ? [] : data.klanten || []
    facturen.value = data.facturen || []
    tab.value = (!klanten.value.length && facturen.value?.length) ? 1 : 0
  }).finally(() => {
    loading.value = false
  })
}, 2500)

const handleSearchWrapper = () => {
  klanten.value = []
  facturen.value = []
  loading.value = !!apiData.value.q
  if (apiData.value.q) {
    handleSearch()
  }
}

const searchItem = useSearchItem()
const handleSelection = (item) => {
  searchItem.value = item
}

watch(apiData, handleSearchWrapper)
onMounted(handleSearchWrapper)
</script>
