import { ref } from 'vue'
import { onBeforeRouteLeave } from 'vue-router'
import useTryIgnore from '@/hooks/useTryIgnore'

const item = ref(null)

export default () => {
  // @TODO: Prefill
  // onMounted(() => {
  //   if (route?.query?.facturatie) {

  //   }
  // })

  useTryIgnore(() => {
    onBeforeRouteLeave(() => {
      item.value = null
    })
  })

  return item
}
