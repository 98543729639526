<template>
  <div v-if="tabs.length">
    <div class="md:hidden">
      <label for="tabs" class="sr-only">Selecteer een tab</label>
      <select
        v-model="selectedTab"
        class="block w-full focus:ring-indigo-500 focus:border-indigo-500 border-gray-300 rounded-md"
      >
        <option v-for="(tab, index) in tabs" :key="tab + index" :value="index">{{ tab }}</option>
      </select>
    </div>
    <div class="hidden md:block">
      <nav class="flex w-full space-x-2" aria-label="Tabs" :key="selectedTab">
        <button
          v-for="(tab, index) in tabs"
          :key="tab + index"
          v-show="!tab?.hidden"
          @click="selectedTab = index"
          class="px-3 py-2 font-medium text-sm rounded-md"
          :disabled="tab?.disabled || false"
          :class="{
            [classButton]: true,
            'bg-indigo-100 text-indigo-700': selectedTab === index,
            'bg-gray-100 text-gray-500 hover:text-gray-700': selectedTab !== index,
          }"
        >
          {{ tab?.label || tab }}
        </button>
      </nav>
    </div>
  </div>
</template>

<script setup>
import { defineProps, defineEmits } from 'vue'
import { useVModel } from '@vueuse/core'

const props = defineProps({
  classButton: String,
  tabs: {
    type: Array,
    default() {
      return []
    },
  },
  modelValue: {
    type: Number,
    default() {
      return 0
    },
  },
})

const emit = defineEmits(['update:tabs', 'update:modelValue'])
const selectedTab = useVModel(props, 'modelValue', emit, { deep: true })

const setTab = index => (selectedTab.value = index)

// -- On create number parse
let valueNumber = parseInt(selectedTab.value > 0 ? selectedTab.value : 0, 10)
valueNumber = Math.min(props.tabs.length, Math.max(0, valueNumber))
setTab(valueNumber)
</script>
